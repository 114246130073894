require('../styles/index.scss');
const Handlebars = require('handlebars/dist/handlebars.js');

const init = () => {
  const data = {
    brands: [
      'WE_ARE_M2',
      'M2_Entertainment',
      'M2_Film',
      'M2_Animation',
    ],
  };

  const source = document.querySelector('#brand-template').innerHTML;
  const template = Handlebars.compile(source);

  document.querySelector('#brands').innerHTML = template(data);
};

init();
